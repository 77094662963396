import {ProductInfo} from "./types";

const DEV_LOCAL_DATA = {
  BASE_URL: '',
  PRODUCT_LINK: (product: ProductInfo) => `/?item=${product.name}#/w9item`,
}

const PROD = {
  BASE_URL: 'https://catalog.kogen.pro',
  PRODUCT_LINK: (product: ProductInfo) => `/product?item=${product.name}`,
}

const DEV_REMOTE_DATA = {
  BASE_URL: PROD.BASE_URL,
  PRODUCT_LINK: DEV_LOCAL_DATA.PRODUCT_LINK,
}

export const {BASE_URL, PRODUCT_LINK} = PROD
