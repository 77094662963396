import React, {useState, useEffect} from 'react';
import ReactSlider from 'react-slider';
import styles from './price_filter.module.scss';

interface PriceFilterProps {
    allowedPriceRange: [number, number];
    priceRange: [number, number];
    setPriceRange: (val: [number, number]) => void;
    thumbStyle?: string;
}

const PriceFilter: React.FC<PriceFilterProps> = ({
                                                     allowedPriceRange,
                                                     priceRange,
                                                     setPriceRange,
                                                     thumbStyle = "example-thumb"
                                                 }) => {
    const [min, max] = priceRange;

    return (
        <div className="checkbox-filter-group__price">
            <h2 className={styles.title}>Цена</h2>
            <div className={styles.mainContainer}>
                <input
                    className={styles.priceInput}
                    value={min}
                    onChange={(ev) =>
                        setPriceRange((() => {
                            const int = parseInt(ev.target.value);
                            if (isNaN(int)) return [min, max];
                            return [int, max];
                          })())
                    }/>
                —
                <input
                    className={styles.priceInput}
                    value={max}
                    onChange={(ev) =>
                        setPriceRange((() => {
                            const int = parseInt(ev.target.value);
                            if (isNaN(int)) return [min, max];
                            return [min, int];
                        })())
                    }/>
            </div>
            <ReactSlider
                className="horizontal-slider"
                thumbClassName={`example-thumb ${thumbStyle}`}
                // thumbClassName="example-thumb-gravel"
                trackClassName="example-track"
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                pearling
                minDistance={10}
                min={allowedPriceRange[0]}
                max={allowedPriceRange[1]}
                onChange={setPriceRange}
                value={priceRange}
            />
        </div>
    );
};

export default PriceFilter;