import React from 'react';
import styles from './productGravel.module.scss';
import {GravelProductInfo} from "../../types";
import ButtonIconCartSvg from "../../components/button/buttonIconCartSvg";
import ButtonCartSvg from "../../components/button/buttonCartSvg";

const ProductGravel = ({product}: { product: GravelProductInfo }) => {
    const addToCart = () => {
        // @ts-ignore fixme: declare global
        window.addToCart({}, '', '', {
            color: '',
            price: product.price
        }, product);
    }
    return (
        <div className={styles.mainContainer}>
            <div className={styles.optionContainer}>
                <div className={`${styles.option} ${styles.optionFirst}`}>{product.fraction}</div>
                <div className={styles.option}>{product.career}</div>
                <div className={styles.option}>{product.strength}</div>
                <div className={`${styles.option} ${styles.gost}`}>{product.gost}</div>
                <div className={`${styles.option} ${styles.pricesDesktop}`}>{product.price} ₽ / {product.retail} ₽</div>
                <div className={styles.pricesModile}>
                    <div className={styles.option}>{product.price} ₽ -&nbsp;</div>
                    <div className={styles.option}>{product.retail} ₽</div>
                </div>
                <div className={styles.iconCartDesktop} onClick={addToCart}>
                    <ButtonIconCartSvg/>
                </div>
            </div>
            <div className={styles.titleContainer}>
                <div className={`${styles.title} ${styles.titleFirst}`}>Фракция</div>
                <div className={styles.title}>Карьер</div>
                <div className={styles.title}>Марка</div>
                <div className={styles.title}>ГОСТ</div>
                <div className={styles.iconCartMobile} onClick={addToCart}>
                    <ButtonCartSvg/>
                </div>
            </div>
        </div>
    );
};

export default ProductGravel;
