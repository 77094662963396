import React from 'react';
import styles from './filterGravelToggleButton.module.scss';

interface ShowMoreButtonProps {
    showAll: boolean;
    onClick: () => void;
}

const FilterGravelToggleButton: React.FC<ShowMoreButtonProps> = ({ showAll, onClick }) => {
    return (
        <button className={styles.checkboxShowMore} onClick={onClick}>
            {showAll ? 'Свернуть' : 'Показать еще'}
            <div className={`${styles.svgCollapse} ${showAll ? '' : styles.rotated}`}>
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L5 1L1 5" stroke="#0E0E0E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </button>
    );
};

export default FilterGravelToggleButton;