import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import styles from './itemPage.module.scss';
import {useCatalog} from "../../useCatalog";
import {Button} from "../../components/button/button";
import {CartItemData, ProductColor, ProductItemInfo} from "../../types";
import Collapsible from 'react-collapsible';
import {BASE_URL} from "../../base";


interface ColorItemProps {
    color: ProductColor
    selected?: boolean
    onSelect: (color: ProductColor) => void
    onAddToCart: () => void
}

const CartIcon = () => <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 17C9.05228 17 9.5 16.5523 9.5 16C9.5 15.4477 9.05228 15 8.5 15C7.94772 15 7.5 15.4477 7.5 16C7.5 16.5523 7.94772 17 8.5 17Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 17C16.0523 17 16.5 16.5523 16.5 16C16.5 15.4477 16.0523 15 15.5 15C14.9477 15 14.5 15.4477 14.5 16C14.5 16.5523 14.9477 17 15.5 17Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.5 1H4.65C4.65 1 5.02165 2.67606 5.25978 3.75C5.7356 5.89591 6.38911 8.84318 6.77252 10.5723C6.95787 11.4081 7.68658 12 8.52792 12H16.2561C17.0998 12 17.8302 11.4032 18.0132 10.5644L19.5 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.5 4H5.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const ColorGroupHeader = (props: { title: string, open: boolean, disabledReason?: string }) =>
    <div className={[styles.colorGroup, props.disabledReason ? styles.disabled : ''].join(' ')}>
        <div className={styles.colorGroupHeader}>{props.title}</div>
        {props.disabledReason ? <span>{props.disabledReason}</span> : <svg className={styles.arrowSvg} viewBox="0 0 26 14" fill="none" style={{
            transform: props.open ? "rotate(-90deg)" : undefined
        }}>
            <path d="M1 1L13 13L25 1" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>}
    </div>

const ColorItem = ({color, onSelect, selected, onAddToCart}: ColorItemProps) =>
    <div className={styles.colorItem}>
        <div className={styles.colorName} onClick={() => onSelect(color)}>{color.color}</div>
        <img onClick={() => onSelect(color)} src={`${BASE_URL}/${color.image}`} className={styles.smallImgMobile} />
        <img onClick={() => onSelect(color)} src={`${BASE_URL}/${color.thumb}`} className={styles.smallImgDesktop} />
        <div className={styles.colorPrice} onClick={() => {
          onAddToCart()
        }}>{color.price} ₽ {selected ?
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="13" fill="#0E0E0E"/>
                <path d="M7 11.5L12.5 16L20.5 9" stroke="white" stroke-width="2"/>
            </svg> : <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.0625" cy="13" r="12.5" stroke="#0E0E0E"/>
                <path
                    d="M10.7779 20.0001C11.1768 20.0001 11.5001 19.6768 11.5001 19.2779C11.5001 18.879 11.1768 18.5557 10.7779 18.5557C10.379 18.5557 10.0557 18.879 10.0557 19.2779C10.0557 19.6768 10.379 20.0001 10.7779 20.0001Z"
                    stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M16.5552 20.0001C16.9541 20.0001 17.2775 19.6768 17.2775 19.2779C17.2775 18.879 16.9541 18.5557 16.5552 18.5557C16.1564 18.5557 15.833 18.879 15.833 19.2779C15.833 19.6768 16.1564 20.0001 16.5552 20.0001Z"
                    stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M5 7H7.52778C7.52778 7 7.82601 8.32053 8.0171 9.16667C8.39894 10.8574 8.92336 13.1795 9.23104 14.5418C9.37977 15.2003 9.96454 15.6667 10.6397 15.6667H16.8413C17.5184 15.6667 18.1045 15.1964 18.2513 14.5356L19.4444 9.16667"
                    stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.4444 9.1665H8.25" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        }</div>

    </div>

const ProductData = ({subitem, margins}: { subitem: ProductItemInfo, margins?: boolean }) => <div className={`${styles.data} ${margins ? styles.margins : ''}`}>
    <div className={styles.dataPoint}>
        <div className={styles.desktopSvg}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path d="M3 45H46.5" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path
                    d="M26.5 4C26.5 3.17157 25.8284 2.5 25 2.5C24.1716 2.5 23.5 3.17157 23.5 4H26.5ZM23.9393 39.0607C24.5251 39.6464 25.4749 39.6464 26.0607 39.0607L35.6066 29.5147C36.1924 28.9289 36.1924 27.9792 35.6066 27.3934C35.0208 26.8076 34.0711 26.8076 33.4853 27.3934L25 35.8787L16.5147 27.3934C15.9289 26.8076 14.9792 26.8076 14.3934 27.3934C13.8076 27.9792 13.8076 28.9289 14.3934 29.5147L23.9393 39.0607ZM23.5 4L23.5 38H26.5L26.5 4H23.5Z"
                    fill="#0E0E0E"/>
            </svg>
        </div>
        <div className={styles.mobileSvg}>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.8081 11.4875C25.8081 10.6591 25.1365 9.98755 24.3081 9.98755C23.4797 9.98755 22.8081 10.6591 22.8081 11.4875L25.8081 11.4875ZM23.2474 32.9829C23.8332 33.5687 24.783 33.5687 25.3688 32.9829L34.9147 23.4369C35.5005 22.8512 35.5005 21.9014 34.9147 21.3156C34.3289 20.7298 33.3792 20.7298 32.7934 21.3156L24.3081 29.8009L15.8228 21.3156C15.237 20.7298 14.2873 20.7298 13.7015 21.3156C13.1157 21.9014 13.1157 22.8512 13.7015 23.4369L23.2474 32.9829ZM22.8081 11.4875L22.8081 31.9222L25.8081 31.9222L25.8081 11.4875L22.8081 11.4875Z"
                    fill="#0E0E0E"/>
                <path d="M13.5298 38.1313H34.4701" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
            </svg>
        </div>
        <div className={styles.dataPointTextContainer}>
            <div className={styles.dataPointTitle}>Количество на поддоне</div>
            <div className={styles.dataPointValue}>{subitem.amountOnOnePalette} м²</div>
        </div>
    </div>
    <div className={styles.dataPoint}>
        <div className={styles.desktopSvg}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path d="M5 45L45 45" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path d="M5 5L5 31.6713" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path d="M13.9229 17.8418L13.9229 31.6714" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path d="M34.2305 17.8418L34.2305 31.6714" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path d="M24.0771 5L24.0771 31.6713" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path d="M43.1538 5L43.1538 31.6713" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
            </svg>
        </div>
        <div className={styles.mobileSvg}>
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.22 37.6029L36.78 37.6029" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                    <path d="M24.5 19.5985L24.5 30.1246" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                    <path d="M34.5 14.4539L34.5 30.1246" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                    <path d="M14.5 14.4539L14.5 30.1246" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                </svg>

            </svg>
        </div>
        <div>
            <div className={styles.dataPointTitle}>Высота</div>
            <div className={styles.dataPointValue}>{subitem.height} мм</div>
        </div>
    </div>
    <div className={styles.dataPoint}>
        <div className={styles.desktopSvg}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path
                    d="M26.9393 20.9393C26.3536 21.5251 26.3536 22.4749 26.9393 23.0607C27.5251 23.6464 28.4749 23.6464 29.0607 23.0607L26.9393 20.9393ZM46.5 5C46.5 4.17157 45.8284 3.5 45 3.5L31.5 3.5C30.6716 3.5 30 4.17157 30 5C30 5.82843 30.6716 6.5 31.5 6.5H43.5V18.5C43.5 19.3284 44.1716 20 45 20C45.8284 20 46.5 19.3284 46.5 18.5L46.5 5ZM29.0607 23.0607L46.0607 6.06066L43.9393 3.93934L26.9393 20.9393L29.0607 23.0607Z"
                    fill="#0E0E0E"/>
                <path
                    d="M23.0607 29.0607C23.6464 28.4749 23.6464 27.5251 23.0607 26.9393C22.4749 26.3536 21.5251 26.3536 20.9393 26.9393L23.0607 29.0607ZM3.5 45C3.5 45.8284 4.17157 46.5 5 46.5L18.5 46.5C19.3284 46.5 20 45.8284 20 45C20 44.1716 19.3284 43.5 18.5 43.5H6.5V31.5C6.5 30.6716 5.82843 30 5 30C4.17157 30 3.5 30.6716 3.5 31.5L3.5 45ZM20.9393 26.9393L3.93934 43.9393L6.06066 46.0607L23.0607 29.0607L20.9393 26.9393Z"
                    fill="#0E0E0E"/>
            </svg>
        </div>
        <div className={styles.mobileSvg}>
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M25.8356 20.6619C25.2498 21.2477 25.2498 22.1974 25.8356 22.7832C26.4214 23.369 27.3711 23.369 27.9569 22.7832L25.8356 20.6619ZM36.5224 13.5965C36.5224 12.768 35.8508 12.0965 35.0224 12.0965L21.5224 12.0964C20.6939 12.0964 20.0224 12.768 20.0224 13.5964C20.0224 14.4248 20.6939 15.0964 21.5224 15.0964L33.5224 15.0965L33.5223 27.0965C33.5223 27.9249 34.1939 28.5965 35.0223 28.5965C35.8507 28.5965 36.5223 27.9249 36.5223 27.0965L36.5224 13.5965ZM27.9569 22.7832L36.083 14.6571L33.9617 12.5358L25.8356 20.6619L27.9569 22.7832Z"
                    fill="#0E0E0E"/>
                <path
                    d="M22.1329 28.6072C22.7187 28.0214 22.7187 27.0716 22.1329 26.4858C21.5471 25.9001 20.5974 25.9001 20.0116 26.4858L22.1329 28.6072ZM11.4774 35.6413C11.4774 36.4698 12.149 37.1413 12.9774 37.1413L26.4774 37.1413C27.3059 37.1413 27.9774 36.4698 27.9774 35.6413C27.9774 34.8129 27.3059 34.1413 26.4774 34.1413H14.4774V22.1413C14.4774 21.3129 13.8059 20.6413 12.9774 20.6413C12.149 20.6413 11.4774 21.3129 11.4774 22.1413L11.4774 35.6413ZM20.0116 26.4858L11.9168 34.5807L14.0381 36.702L22.1329 28.6072L20.0116 26.4858Z"
                    fill="#0E0E0E"/>
            </svg>
        </div>
        <div>
            <div>
                <div className={styles.dataPointTitle}>Размер</div>
                <div className={styles.dataPointValue}>{subitem.size.split(/\s/g).map((size, index) => (
                    <div className={styles.sizeItem}>
                        {size} <span>мм</span>
                    </div>
                ))}</div>
            </div>
        </div>
    </div>
    <div className={styles.dataPoint}>
        <div className={styles.desktopSvg}>
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                <path d="M25 21L25 35.2983" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path
                    d="M46.4943 19.7615C29.0276 2.25693 10.9018 12.1412 3.47893 19.7712C3.18512 20.0732 3.12931 20.5255 3.32334 20.8995L12.6648 38.9056C12.9574 39.4695 13.6943 39.62 14.2045 39.2413C23.3603 32.4455 31.9825 35.8482 36.0835 39.1028C36.6132 39.5232 37.4177 39.3836 37.7208 38.779L46.6821 20.9102C46.8725 20.5304 46.7944 20.0622 46.4943 19.7615Z"
                    stroke="#0E0E0E" stroke-width="3"/>
            </svg>
        </div>
        <div className={styles.mobileSvg}>
            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 21.7888L24 31.9053" stroke="#0E0E0E" stroke-width="3" stroke-linecap="round"/>
                <path
                    d="M39.0599 20.7651C26.8609 8.69551 14.239 15.4156 8.9196 20.7701C8.62264 21.0691 8.56339 21.5259 8.75742 21.8999L15.1062 34.1375C15.3988 34.7015 16.1387 34.8487 16.6567 34.4809C22.7973 30.1212 28.5587 32.1692 31.5244 34.3544C32.0688 34.7556 32.8762 34.6155 33.1794 34.011L39.2477 21.9106C39.4382 21.5309 39.3619 21.0639 39.0599 20.7651Z"
                    stroke="#0E0E0E" stroke-width="3"/>
            </svg>
        </div>
        <div>
            <div className={styles.dataPointTitle}>Вес поддона</div>
            <div className={styles.dataPointValue}>{subitem.weightPalette} кг</div>
        </div>
    </div>
</div>

export const ItemPage: React.FC = () => {
    const {currentItem} = useCatalog()
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectedSubitem = useMemo(
        () => currentItem?.subitems?.[selectedIndex],
        [currentItem, selectedIndex])

    const [openColorGroups, setOpenColorGroups] = useState([0])
    const toggleGroup = (i: number) => setOpenColorGroups(state => {
        const newState = [...state]
        const index = newState.indexOf(i)
        if (index === -1)
            newState.push(i)
        else
            newState.splice(index)
        return newState
    })

    if (!selectedSubitem || !currentItem) return null

    // @ts-ignore fixme: declare global
    const {w9InvokeForm, w9ColorWarning, openPhoto, addToCart} = window;

    const pricesEntries = Object.entries(selectedSubitem.prices)
    const isShort = selectedSubitem.type === 'бордюры' && pricesEntries.length === 1 && pricesEntries[0][1].length === 1
    const firstColor = pricesEntries[0]
    const firstPrice = firstColor[1][0].price
    const addFirstToCart = () => {
        addToCart(currentItem, selectedSubitem, firstColor[0], firstColor[1][0])
      }
    return (
        <div className={styles.page}>
            <div className={styles.details}>
                <img src={`${BASE_URL}/${currentItem.bigImage}`} className={styles.image}/>
                {!isShort && <h1 className={styles.mobileHeader}>{selectedSubitem.name}</h1>}
                {currentItem.subitems?.length > 1 && <div className={styles.sizeAdaptiveMobile}>
                    {isShort && <Button title={`${firstPrice} ₽`} onClick={addFirstToCart}/>}
                    {!isShort && <div className={styles.tileHeight}>{"Высота плитки"}</div>}
                    {!isShort && <div className={styles.buttonsContainerMobile}>
                        {currentItem.subitems.map((it, i) =>
                            <Button
                                title={`${it.height} мм`}
                                type={selectedIndex === i ? 'black' : 'white'}
                                unhoverable={selectedIndex === i}
                                onClick={() => setSelectedIndex(i)}
                                // mobileAdaptive={styles.mobileAdaptiveButtons}
                            />)}
                    </div>}
                </div>}
                {!isShort && <ProductData subitem={selectedSubitem}/>}
            </div>

            <div className={styles.picker}>
                <h1 className={styles.desktopHeader}>{selectedSubitem.name}</h1>
                {!isShort && currentItem.subitems?.length > 1 && <div className={styles.size}>
                    <div style={{marginRight: 70}}>{"Высота плитки"}</div>
                    {currentItem.subitems.map((it, i) =>
                        <Button
                            title={`${it.height} мм`}
                            type={selectedIndex === i ? 'black' : 'white'}
                            unhoverable={selectedIndex === i}
                            onClick={() => setSelectedIndex(i)}
                        />)}
                </div>}
                {isShort && <ProductData subitem={selectedSubitem} margins/>}
                {!isShort && <div className={styles.colorsContainer}>
                    {Object.entries(selectedSubitem.prices).map(([group, colors], i) => {
                            const isOpen = openColorGroups.includes(i)
                            if(i === 0) console.log(isOpen)
                            return <Collapsible
                                trigger={<ColorGroupHeader title={group} open={isOpen}/>}
                                open={isOpen} onTriggerOpening={() => toggleGroup(i)}
                                onTriggerClosing={() => toggleGroup(i)}
                                contentOuterClassName={styles.colorContainer}>
                                <div className={styles.colors}>
                                    {colors.map(color => <ColorItem color={color} onSelect={it => {
                                        openPhoto(`${BASE_URL}/${it.image}`)
                                    }} onAddToCart={() => {
                                      addToCart(currentItem, selectedSubitem, group, color)
                                    }}/>)}
                                </div>
                            </Collapsible>
                        }
                    )}
                    {selectedSubitem.disabledGroups?.map(({type, reason}) => <ColorGroupHeader title={type} open={false} disabledReason={reason}/>)}
                </div>}
                <div className={styles.actionRow}>
                    {isShort && <Button title={`${firstPrice} ₽`} onClick={addFirstToCart}><CartIcon/></Button>}
                    {!isShort && <Button title="Получить консультацию" onClick={w9InvokeForm}/>}
                    {!isShort && <Button title="Предупреждение о цветопередаче" type='black' onClick={w9ColorWarning}/>}
                </div>
            </div>
        </div>
    );
};
