import React, {useEffect, useMemo, useState} from "react";
import styles from "./cartPage.module.scss";
import {calculatePriceRange, formatPrice, useCatalog} from "../../useCatalog";
import {CartItem} from "../../components/cartItem/cartItem";
import { CartItemData } from "../../types";


const useCart = () => {
    const [cartItems, setCartItems] = useState<CartItemData[]>([]);
    const totalPrice = useMemo(() => cartItems.reduce((acc, val) => acc + val.price * val.quantity, 0), [cartItems])
    // @ts-ignore
    const {addToCart, removeFromCart, setCartCount, subscribeToCart} = window
    useEffect(() => {
        subscribeToCart((cartObj: any) => setCartItems(Object.values(cartObj)))
    }, [])
    return {cartItems, addToCart, removeFromCart, setCartCount, totalPrice}
}

export const CartPage: React.FC = () => {
    const {dataArray, heights, defaultPriceRange} = useCatalog();
    const {cartItems, removeFromCart, setCartCount, totalPrice} = useCart();

    const [displayedProduct, setDisplayedProduct] = useState(3);

    // Передать массив объектов со списком идентификаторов товаров
    // const itId = useMemo(() => {
    //     return dataArray.flatMap((item) =>
    //         item.subitems.flatMap((subitem) =>
    //             Object.entries(subitem.prices).flatMap(([type, colors]) =>
    //                 colors.map((productColor) => ({
    //                     key: `${item.name}_${formatPrice(productColor.price)}_${subitem.height}_${type}_${productColor.color}`,
    //                     title: item.name,
    //                     price: formatPrice(productColor.price),
    //                     height: subitem.height,
    //                     type: type,
    //                     color: productColor.color,
    //                 }))
    //             )
    //         )
    //     );
    // }, [dataArray]);

    const CartTotalPrice = () => {
        return (
            <div className={styles.itemTotalPriceContainer}>
                <div className={styles.titlePrice}>Сумма к оплате:</div>
                <div className={styles.totalPrice}>{totalPrice} ₽</div>
            </div>
        );
    };

    return (
        <div className={styles.page}>
            {/* <div className={styles.titleOrderContainer}>
                <h1 className={styles.titleOrder}>Ваш заказ</h1>
                <svg className={styles.svgClose} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 19L19 1M1 1L19 19L1 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> */}
            <div className={styles.itemContainer}>
                {cartItems.map((it) =>
                <CartItem
                    key={it.key}
                    item={it}
                    setQuantity={setCartCount}
                    onDelete={removeFromCart}
                />)}
            </div>
            <CartTotalPrice/>
        </div>
    );
};
