import React, {PropsWithChildren} from 'react';
import styles from './button.module.scss';

interface ButtonProps {
    title: string;
    onClick?: () => void;
    type?: 'white' | 'black';
    unhoverable?: boolean;
    maxWidth?:boolean;
}

export const Button = (props: PropsWithChildren<ButtonProps>) => {

    return (
        <button className={[
            styles[props.type || 'white'],
            props.unhoverable ? '' : styles.hoverable,
            props.maxWidth ? styles.maxWidth : '',
        ].join(' ')} onClick={props.onClick}>{props.title}{props.children}</button>
    );
};

