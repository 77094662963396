export function catalogCheckboxChange<T>(
    value: T,
    selectedItems: Set<T>,
    setSelectedItems: (items: Set<T>) => void
) {
    // @ts-ignore
    const setCopy = new Set([...selectedItems]);
    if (selectedItems.has(value)) {
        setCopy.delete(value);
    } else {
        setCopy.add(value);
    }
    setSelectedItems(setCopy);
}

