import React from 'react';

const ButtonIconCartSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="19.5" stroke="#0E0E0E"/>
                <path d="M16.4842 30.7686C17.0978 30.7686 17.5953 30.2711 17.5953 29.6575C17.5953 29.0438 17.0978 28.5464 16.4842 28.5464C15.8705 28.5464 15.373 29.0438 15.373 29.6575C15.373 30.2711 15.8705 30.7686 16.4842 30.7686Z" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M25.3728 30.7686C25.9865 30.7686 26.4839 30.2711 26.4839 29.6575C26.4839 29.0438 25.9865 28.5464 25.3728 28.5464C24.7592 28.5464 24.2617 29.0438 24.2617 29.6575C24.2617 30.2711 24.7592 30.7686 25.3728 30.7686Z" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.5957 10.769H11.4846C11.4846 10.769 11.9434 12.8006 12.2374 14.1024C12.8248 16.7035 13.6316 20.2759 14.105 22.3718C14.3338 23.3849 15.2335 24.1024 16.2721 24.1024H25.8131C26.8547 24.1024 27.7565 23.3789 27.9824 22.3623L29.8179 14.1024" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M29.8179 14.1021H12.5957" stroke="#0E0E0E" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </svg>
    );
};

export default ButtonIconCartSvg;