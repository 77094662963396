import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {CatalogPage} from "./pages/catalog/catalogPage";
import {ItemPage} from "./pages/item/itemPage";
import {CartPage} from "./pages/cart/cartPage";
import {CatalogPageGravel} from "./pages/catalog/catalogPageGravel";

const widgets = {
  "w9catalog": CatalogPage,
  "w9cStone": CatalogPageGravel,
  "w9item": ItemPage,
  "w9cart": CartPage,
}
Object.entries(widgets).forEach(([id, Widget]) => {
  const el = document.getElementById(id) as HTMLElement
  if(!el) return
  ReactDOM.createRoot(el).render(
    <React.StrictMode>
      <Widget />
    </React.StrictMode>

  );
})

