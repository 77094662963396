import React, {useState} from 'react';
import './checkboxFilterGroup.scss';

interface CheckboxProps {
  label: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, value, onChange }) => {
  return (
    <div className="checkbox-filter-group__checkbox">
      <input type="checkbox" id={label} name={label} checked={value} onChange={ev => onChange?.(ev.target.checked)} />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

interface CheckboxFilterGroupProps {
  children: React.ReactNode;
}

const CheckboxFilterGroup: React.FC<CheckboxFilterGroupProps> = ({ children }) => {
  return <div className="checkbox-filter-group">{children}</div>;
};



export { Checkbox, CheckboxFilterGroup };


