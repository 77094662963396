import React from 'react';
import './search.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

interface SearchProps {
  value?: string,
  onChange?: (value: string) => void
}

const Search = (props: SearchProps) => {
  return (
    <div className="search-container">
      <input type="text" className="search-input" placeholder="Поиск..." value={props.value}
             onChange={ev => props.onChange?.(ev.target.value)}/>
      <div className="search-icon">
        <FontAwesomeIcon icon={faSearch}/>
      </div>
    </div>
  );
};

export default Search;
