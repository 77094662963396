import { HTMLProps, ReactNode } from "react";
import styles from "./buttonComponent.module.scss";

export const ButtonComponent = ({
  title,
  LeftIcon: LeftIcon,
  className,
  children,
  onClick,
}: {
  title?: string;
  LeftIcon?: string | ((props: HTMLProps<SVGSVGElement>) => ReactNode)
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div onClick={onClick} className={[styles.container, className].join(" ")}>
      {LeftIcon || title ? (
        <>
          {typeof LeftIcon === "string" ? <img className={styles.leftIcon} src={LeftIcon} /> : LeftIcon && <LeftIcon className={styles.leftIcon}/>}
          <div className={styles.title}>{title}</div>
        </>
      ) : (
        children
      )}
    </div>
  );
};
