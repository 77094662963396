import styles from './buttonCartSvg.module.scss'
import React, {useState} from 'react';

const ButtonCartSvg = () => {
    const [inCart, setInCart] = useState(false);

    const handleClick = () => {
        setInCart(!inCart);
    };

    return (
        <div>
            {inCart ? (
                <button onClick={handleClick} className={styles.buttonInCart}>
                        <svg className={styles.svgCart} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="14" fill="#0E0E0E"/>
                            <path d="M7.12695 13.267L11.4906 17.6306C11.8812 18.0211 12.5143 18.0211 12.9049 17.6306L20.8734 9.66211" stroke="white" stroke-linecap="round"/>
                        </svg>
                    В корзине
                </button>
            ) : (
                <button onClick={handleClick} className={styles.buttonAddToCart}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" fill="white"/>
                        <rect x="0.5" y="0.5" width="27" height="27" rx="13.5" stroke="#0E0E0E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.08594 7.86719C5.08594 7.59105 5.3098 7.36719 5.58594 7.36719H8.11372C8.34742 7.36719 8.54995 7.52908 8.60143 7.75704L9.00265 9.53355H20.0304C20.2772 9.53355 20.4822 9.71233 20.523 9.94743C20.532 9.99865 20.5331 10.052 20.5252 10.1058C20.5231 10.1204 20.5204 10.1347 20.517 10.1489L19.3254 15.5112C19.1277 16.4008 18.3386 17.0339 17.4273 17.0339H11.2256C10.3174 17.0339 9.52961 16.4062 9.32926 15.5191L7.71404 8.36719H5.58594C5.3098 8.36719 5.08594 8.14333 5.08594 7.86719ZM19.4071 10.5336H9.22849L10.3047 15.2988C10.4018 15.7288 10.7836 16.0339 11.2256 16.0339H17.4273C17.8699 16.0339 18.2532 15.7264 18.3492 15.2943L19.4071 10.5336Z" fill="#0E0E0E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3636 18.9226C10.6886 18.9226 10.1414 19.4698 10.1414 20.1449C10.1414 20.8199 10.6886 21.3671 11.3636 21.3671C12.0386 21.3671 12.5858 20.8199 12.5858 20.1449C12.5858 19.4698 12.0386 18.9226 11.3636 18.9226ZM11.1414 20.1449C11.1414 20.0221 11.2408 19.9226 11.3636 19.9226C11.4863 19.9226 11.5858 20.0221 11.5858 20.1449C11.5858 20.2676 11.4863 20.3671 11.3636 20.3671C11.2408 20.3671 11.1414 20.2676 11.1414 20.1449Z" fill="#0E0E0E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1414 18.9226C16.4664 18.9226 15.9192 19.4698 15.9192 20.1449C15.9192 20.8199 16.4664 21.3671 17.1414 21.3671C17.8164 21.3671 18.3636 20.8199 18.3636 20.1449C18.3636 19.4698 17.8164 18.9226 17.1414 18.9226ZM16.9192 20.1449C16.9192 20.0221 17.0187 19.9226 17.1414 19.9226C17.2641 19.9226 17.3636 20.0221 17.3636 20.1449C17.3636 20.2676 17.2641 20.3671 17.1414 20.3671C17.0187 20.3671 16.9192 20.2676 16.9192 20.1449Z" fill="#0E0E0E"/>
                    </svg>
                    Добавить
                </button>
            )}
        </div>
    );
};

export default ButtonCartSvg;