import React, {useMemo} from 'react';
import './productCategory.scss';
import {ProductInfo} from "../../types";
import {PRODUCT_LINK} from "../../base";
import { getProductImage } from './getProductImage';

const ProductCategory = ({product}: {product: ProductInfo}) => {
  // @ts-ignore fixme: discuss with the client
  const handler = () => window.addToCart?.(product, product.subitems[0], Object.keys(product.subitems[0].prices)[0], Object.values(product.subitems[0].prices)[0][0])
  return (
    <div className="product-category">
      <a href={PRODUCT_LINK(product)}>
        <img className="product-image" src={getProductImage(product.image)}/>
      </a>
      <a className="category-title" href={PRODUCT_LINK(product)}>{product.name}</a>
      <div className="products-container">
        <div className="product-info">{product.size}</div>
      </div>
      <div className="price-info">от {product.minPrice.toLocaleString('ru')} ₽ / м²</div>
      <div className="action-buttons">
        <a className="details-button" href={PRODUCT_LINK(product)}>Подробнее</a>
        <button className="add-to-cart-button" onClick={handler}>В корзину</button>
      </div>
    </div>
  );
};

export default ProductCategory;
