import {useEffect, useMemo, useState} from "react";
import {BASE_URL} from "./base";
import {GravelProductInfo, ProductInfo} from "./types";

export const calculatePriceRange = (info: ProductInfo[]): [number, number] => {
    if (info.length === 0) return [0, 0]
    return [Math.min(...info.map(it => it.minPrice)), Math.max(...info.map(it => it.maxPrice))]
}

export const calculatePriceRangeGravel = (info: GravelProductInfo[]): [number, number] => {
    if (info.length === 0) return [0, 0];
    const minPrice = Math.min(...info.map(it => it.price));
    const maxPrice = Math.max(...info.map(it => it.price));
    return [minPrice, maxPrice]
}
export const formatPrice = (price: number) => {
    return price.toLocaleString('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    });
};

export const useCatalog = () => {
    const [data, setData] = useState({})
    useEffect(() => {
        fetch(`${BASE_URL}/static/data.json`).then(it => it.json()).then(it => setData(it))
    }, [])

    return useMemo(() => {
        const params = new URL(document.location.href).searchParams
        const type = params.get("type") || "плитка тротуарная";
// @ts-ignore
        const dataArray: ProductInfo[] = Object.values(data[type] || {})

// @ts-ignore
        const heights: string[] = [...new Set(dataArray.map(it => it.subitems.map(it => it.height)).flat()).values()].sort((a, b) => parseInt(a) - parseInt(b))

        const defaultPriceRange = calculatePriceRange(dataArray)

        const allItems: Record<string, ProductInfo> = Object.assign({}, ...Object.values(data))

        const item = params.get("item") || "Брусчатка \"прямоугольник\"";
        const currentItem = allItems[item] || null


// @ts-ignore
        const dataGravel: GravelProductInfo[] = data['щебень'] || [];

// @ts-ignore
        const fractions: string[] = [...new Set(dataGravel.map(it => it.fraction))].sort((a, b) => {
            const [aNum, bNum] = [parseFloat(a.split('-')[0]), parseFloat(b.split('-')[0])];
            return aNum - bNum;
        });
// @ts-ignore
        const careers: string[] = [...new Set(dataGravel.map(it => it.career))].sort((a, b) => a.localeCompare(b));
// @ts-ignore
        const strengths: string[] = [...new Set(dataGravel.map(it => it.strength))].sort((a, b) => b.localeCompare(a));
// @ts-ignore
        const gosts: string[] = [...new Set(dataGravel.map(it => it.gost))].sort((a, b) => {
            const [aNum, bNum] = [parseFloat(b.split('-')[0]), parseFloat(a.split('-')[0])];
            return aNum - bNum;
        });

        const defaultPriceRangeGravel = calculatePriceRangeGravel(dataGravel);

        return {
            dataArray, heights, defaultPriceRange, allItems, currentItem,
            dataGravel, fractions, careers, strengths, gosts, defaultPriceRangeGravel,
        }
    }, [data])
}
