import { ButtonComponent } from "../buttonComponent/buttonComponent";
import styles from "./quantityAdjustmentButton.module.scss";

export const QuantityAdjustmentButton = ({
  value,
  onChange,
  className,
}: {
  value: number;
  onChange: (it: number) => void;
  className?: string;
}) => {
  function add() {
    onChange(value + 1);
  }
  function minus() {
    if (value > 0) {
      onChange(value - 1);
    }
  }
  return (
    <ButtonComponent className={[styles.container, className].join(" ")}>
      <div onClick={minus} className={`${styles.iconAdjustmentButton} ${styles.minus}`}>
        -
      </div>
      <input
        type={"number"}
        className={styles.content}
        value={value}
        onChange={(it) => {
          if (!isNaN(Number(it.target.value))) {
            onChange(Number(it.target.value));
          }
        }}
      />
      <div onClick={add} className={`${styles.iconAdjustmentButton} ${styles.plus}`}>
        +
      </div>
    </ButtonComponent>
  );
};
