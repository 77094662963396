import styles from "./cartItem.module.scss";
import { ButtonComponent } from "../buttonComponent/buttonComponent";
import binIcon from "../../assets/icons/bin.svg";
import { QuantityAdjustmentButton } from "../quantityAdjustmentButton/quantityAdjustmentButton";
import { useMemo, useState } from "react";
import { CartItemData } from "../../types";
import { getProductImage } from "../product/getProductImage";

const NOTHING_IMG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg=='

export const CartItem = ({
  item,
  setQuantity,
  onDelete,
}: {
  item: CartItemData
  setQuantity?: (item: CartItemData, quantity: number) => void;
  onDelete?: (item: CartItemData) => void;
}) => {
  const  {
    price,
    type,
    color,
    image,
    subitem,
    quantity,
    gravel,
  } = item;

  const totalCost = useMemo(() => (price * quantity) || price || 0, [item])

  const options = (() => {
    if(gravel)
      return [gravel.career, `Фракция ${gravel.fraction}`, `Прочность ${gravel.strength}`]
    if(subitem)
      return [type, color, `Высота ${subitem.height}`]
    return []
  })()

  return (
      <div>
        <div className={styles.itemContainer}>
          <img className={styles.image} src={gravel ? NOTHING_IMG : getProductImage(image)}/>
          <div className={styles.itemInfoContainer}>
            <div className={styles.topContainer}>
              <div>{gravel ? `Щебень ГОСТ ${gravel.gost}` : subitem?.name}</div>
              <div className={styles.priceDesktop}>{totalCost.toLocaleString('ru')} ₽</div>
            </div>
            <div className={styles.optionsContainer}>
              {options.map(option => <div className={styles.option}>{option}</div>)}
            </div>
            <div className={styles.itemPrice}>
              <div className={styles.priceMobile}>{totalCost.toLocaleString('ru')} ₽</div>
            </div>
            <div className={styles.buttonContainer}>
              <QuantityAdjustmentButton
                  className={styles.leftbutton}
                  value={quantity}
                  onChange={(val) => setQuantity?.(item, val)}
              />
              <ButtonComponent
                  title="Удалить"
                  LeftIcon={(props) => <svg {...props} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 5V3C6.75 1.89543 7.64543 1 8.75 1H12.75C13.8546 1 14.75 1.89543 14.75 3V5M1.75 5H19.75H1.75ZM3.75 5V19C3.75 20.1046 4.64543 21 5.75 21H15.75C16.8546 21 17.75 20.1046 17.75 19V5H3.75Z" stroke="#0E0E0E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.75 10V16" stroke="#0E0E0E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.75 10V16" stroke="#0E0E0E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  }
                  onClick={() => onDelete?.(item)}
              />
            </div>
          </div>
        </div>
      </div>

  );
};
